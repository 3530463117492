import { render, staticRenderFns } from "./BoardHeader.vue?vue&type=template&id=11c92776&scoped=true&"
import script from "./BoardHeader.vue?vue&type=script&lang=js&"
export * from "./BoardHeader.vue?vue&type=script&lang=js&"
import style0 from "./BoardHeader.vue?vue&type=style&index=0&id=11c92776&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c92776",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BoardSelect: require('/codebuild/output/src4163292307/src/hogc-one-frontend/components/Board/Input/BoardSelect.vue').default})
