import dayjs from 'dayjs'
import markerSDK from '@marker.io/browser'
import { formatDate } from '@/util/formatDateToTime'

const INITIAL_STATE = {
  id: undefined,
  personalId: undefined,
  children: [],
  dob: undefined,
  dob_ddmm: undefined,
  email: { email: undefined },
  emailVerified: undefined,
  firstGenChristian: undefined,
  gender: undefined,
  haveChildren: undefined,
  lifephases: [],
  maritalStatus: undefined,
  memberCgZones: [],
  name: '',
  nationalityId: undefined,
  numberOfChildren: undefined,
  phones: [],
  preHogcId: undefined,
  residentialId: undefined,
  surname: '',
  uin: null,
  loading: false,
  pastoralAbbrevMapping: {},
  ministryStatus: '',
  hasSeenLdrsCal: false,
  hasSeenUpdates: false,
  igHandle: '',
  isLeader: false,
}

export const state = () => INITIAL_STATE

export const mutations = {
  setProfileState (state, newProfile) {
    for (const key in newProfile) state[key] = newProfile[key]
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setPastoralAbbrevMapping (state, { value }) {
    state.pastoralAbbrevMapping = value
  },
  setMinistryStatus (state, ministryStatus) {
    state.ministryStatus = ministryStatus
  },
  setHasSeenLdrsCal (state, hasSeenLdrsCal) {
    state.hasSeenLdrsCal = hasSeenLdrsCal
  },
  setHasSeenUpdates (state, hasSeenUpdates) {
    state.hasSeenUpdates = hasSeenUpdates
  },
  setIsLeader (state, isLeader) {
    state.isLeader = isLeader
  },
}

export const actions = {
  async fetchProfile ({ dispatch, state }) {
    // Will not call backend api if profile already set
    if (state.name) {
      if (process.env.NODE_ENV === 'staging') {
        markerSDK.loadWidget({
          destination: process.env.MARKERIO_DESTINATION, // <- Your unique destination ID
          reporter: {
            email: state.email.email,
            fullName: state.name,
          },
        })
      }
      return
    }
    await dispatch('forceFetchProfile')
    window.$crisp.push(['set', 'user:email', [state.email.email]])
    window.$crisp.push(['set', 'user:nickname', [state.name]])
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg)
    if (filteredMemberCgZone.length) {
      window.$crisp.push(['set', 'session:data', ['cg', filteredMemberCgZone[0].cg.cgName]])
      window.$crisp.push(['set', 'session:data', ['role', filteredMemberCgZone[0].roleId]])
    }
  },
  async fetchPastoralAbbrevMapping ({ state, commit, dispatch }) {
    if (Object.keys(state.pastoralAbbrevMapping).length) return

    try {
      commit('setLoading', true)
      const { data } = await this.$axios.get('/profile/pastoral-abbrev-mapping')
      commit('setPastoralAbbrevMapping', { value: data })
      commit('setLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch roleid abbrev mapping', { root: true })
    }
  },
  async forceFetchProfile ({ commit, dispatch }) {
    commit('setLoading', true)

    try {
      commit('setLoading', true)
      const { status, data } = await this.$axios.get('/profile')

      if (status !== 200) throw new Error('Failed in setting profile')

      // strip time and timezone context for date of birth so that it
      // always display the same date no matter the timezone
      if (data.dob) {
        const dob = new Date(data.dob)
        const date = new Date(dob.getFullYear(), dob.getUTCMonth(), dob.getUTCDate())

        data.dob = formatDate(date, false)
      }

      commit('setProfileState', data)
      commit('setLoading', false)

      window.$crisp.push(['set', 'user:id', [data.id]])
      window.$crisp.push(['set', 'user:email', [data.email.email]])
      window.$crisp.push(['set', 'user:nickname', [data.surname + ' ' + data.name]])
      const filteredMemberCgZone = data.memberCgZones.filter((c) => c.primaryCg)
      if (filteredMemberCgZone.length) {
        window.$crisp.push(['set', 'session:data', ['cg', filteredMemberCgZone[0].cg.cgName]])
        window.$crisp.push(['set', 'session:data', ['role', filteredMemberCgZone[0].roleId]])
      }
      const roleMap = {
        1: 'CG Counsellor',
        2: 'PTL',
        3: 'CGL',
        4: 'R',
        5: 'TL',
        6: 'PCGL',
        7: 'EVTL',
        8: 'ZM',
        9: 'SCGL',
        10: 'G',
        11: 'ACGL',
        12: 'ZL',
        13: 'V',
        14: 'PZM',
        15: 'SZL',
        16: 'N',
        17: 'I',
        18: 'GI',
      }
      window.dataLayer.push({
        event: 'login',
        id: data.id,
        name: data.surname + ' ' + data.name,
        pastoralStatus: roleMap[filteredMemberCgZone[0].roleId],
        cg: data.memberCgZones[0].cg.cgName,
        zone: data.memberCgZones[0].cg.zone.zoneName,
      })
    } catch (e) {
      dispatch('toast/error', 'Failed to load profile.', { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  async updateProfile ({ commit, dispatch }, updatedProfile) {
    commit('setLoading', true)
    try {
      await this.$axios.put('/profile/update', updatedProfile)
      dispatch('toast/show', 'Successfully updated user profile detail', { root: true })
      dispatch('forceFetchProfile')
    } catch (error) {
      dispatch('toast/error', 'Error updating profile.', { root: true })
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchMinistryStatus ({ state, commit, dispatch }) {
    if (state.ministryStatus) return

    try {
      const { data } = await this.$axios.get('/profile/ministry-status')
      commit('setMinistryStatus', data)
    } catch (error) {
      dispatch('toast/error', 'Error getting ministry status', { root: true })
    }
  },

  async updateHasSeenLdrsCal ({ commit }) {
    commit('setHasSeenLdrsCal', true)
    await this.$axios.put('/profile/update', { hasSeenLdrsCal: true })
  },

  async updateHasSeenUpdates ({ commit }) {
    commit('setHasSeenUpdates', true)
    await this.$axios.put('/profile/update', { hasSeenUpdates: true })
  },

  updateIsLeader ({ commit }, isLeader) {
    commit('setIsLeader', isLeader)
  },
}

export const getters = {
  cgId (state) {
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg)
    if (filteredMemberCgZone.length) return filteredMemberCgZone[0].cgId_zoneId
    return null
  },
  cgName (state) {
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg)
    if (filteredMemberCgZone.length) return filteredMemberCgZone[0].cg.cgName
    return null
  },
  zoneName (state) {
    const filteredMemberCgZone = state.memberCgZones.filter((c) => c.primaryCg)
    if (filteredMemberCgZone.length) return filteredMemberCgZone[0].cg.zone.zoneName
    return null
  },
  age (state) {
    return new Date().getFullYear() - new Date(state.dob).getFullYear()
  },
  fullname (state) {
    return `${state.surname} ${state.name}`
  },
  email (state) {
    return state.email.email
  },
  encodedEmail (state) {
    return state.email.email
  },
  localMobileNumber (state) {
    const filteredPhones = state.phones.filter((p) => p.phoneTypeId === 1 && p.primaryPhone === 1)
    if (filteredPhones.length) return filteredPhones[0].phone
    return ''
  },
  countryCode (state) {
    const filteredPhones = state.phones.filter((p) => p.phoneTypeId === 1 && p.primaryPhone === 1)
    if (filteredPhones.length) return filteredPhones[0].countryCode
    return ''
  },
  pastoralRoleId (state) {
    if (state.memberCgZones.length) {
      return state.memberCgZones[0]?.roleId
    }
  },
  genderLongAbbr (state) {
    if (state.gender === 'M') return 'Male'
    if (state.gender === 'F') return 'Female'
    return null
  },
  pastoralStatus (state) {
    return state.pastoralAbbrevMapping[state.memberCgZones[0]?.roleId]
  },
  isLeader (state) {
    return state.isLeader
  },
  payload (state) {
    if (!state.name && !state.surname) {
      return {
        fullname: undefined,
        gender: undefined,
        dob: undefined,
        dob_ddmm: undefined,
        nationalityId: undefined,
        residentialId: undefined,
        maritalStatus: undefined,
        numberOfChildren: undefined,
        uin: undefined,
        haveChildren: undefined,
        preHogcId: undefined,
        firstGenChristian: undefined,
        childrenBirthYears: [],
        phaseId: undefined,
        levelRankId: undefined,
        educationalPhaseYear: undefined,
        school: undefined,
        courseIndustry: undefined,
        partTimeStudy: undefined,
      }
    }
    let dob = dayjs(state.dob).format('YYYY-MM-DD')
    if (dob === 'Invalid Date') dob = undefined // to maintain falsy value if invalid date

    const childrenBirthYears = []
    if (state.haveChildren && state.numberOfChildren && state.children.length) {
      for (let i = 0; i < state.numberOfChildren; i++) {
        childrenBirthYears.push(state.children[0][`childBirthYear${i + 1}`])
      }
    }

    for (let i = 0; i < 10 - state.numberOfChildren; i++) {
      childrenBirthYears.push(undefined)
    }

    return {
      fullname: `${state.surname} ${state.name}`,
      gender: state.gender,
      dob,
      dob_ddmm: state.dob_ddmm,
      nationalityId: state.nationalityId ?? 1,
      residentialId: state.residentialId,
      maritalStatus: state.maritalStatus,
      numberOfChildren: state.numberOfChildren,
      uin: state.uin,
      haveChildren: state.haveChildren,
      preHogcId: state.preHogcId,
      firstGenChristian: state.firstGenChristian,
      childrenBirthYears,
      phaseId:
        state.lifephases && state.lifephases.length ? state.lifephases[0].phaseId : undefined,
      levelRankId:
        state.lifephases && state.lifephases.length ? state.lifephases[0].levelRankId : undefined,
      educationalPhaseYear:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].educationalPhaseYear
          : undefined,
      school: state.lifephases && state.lifephases.length ? state.lifephases[0].school : undefined,
      courseIndustry:
        state.lifephases && state.lifephases.length
          ? state.lifephases[0].courseIndustry
          : undefined,
      partTimeStudy:
        state.lifephases && state.lifephases.length ? state.lifephases[0].partTimeStudy : undefined,
      igHandle: state.igHandle,
    }
  },
}
